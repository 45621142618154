

import { NavItem } from "@/types"
import { Dispatch, SetStateAction } from "react"
import { NavMenuItem } from "./navMenuItem"


interface MainNavigationProps {
    items: NavItem[],
    user: any,
    editPinnedMenu: boolean,
    setOpen?: Dispatch<SetStateAction<boolean>>
}

export function MainNavigation({ items, user, setOpen, editPinnedMenu }: MainNavigationProps) {
    if (!items?.length) {
        return null
    }

    return (
        <nav className="grid items-start gap-2">
            {items.map((item, index) => {
                return (
                    item.to && (
                        <NavMenuItem key={index} item={item} level={0} user={user} setOpen={setOpen} editPinnedMenu={editPinnedMenu} />
                    )
                )
            })}
        </nav>
    )
}
