import Header from "./layout/header"
import Footer from "./layout/footer"
import Background from "./layout/background"
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faHome, faInfoCircle, faRabbitRunning, faSitemap,
    faWarehouseFull, faBoxesStacked, faSprayCan, faTruck, faTruckRampBox,
    faConveyorBeltArm, faPuzzle, faTrophyStar,
    faBagsShopping, faBuildingShield, faBedBunk
} from '@fortawesome/pro-light-svg-icons'

import { ScrollArea } from "./ui/scroll-area"

library.add(
    faHome,
    faInfoCircle,
    faRabbitRunning,
    faSitemap,
    faWarehouseFull,
    faBoxesStacked,
    faSprayCan,
    faTruckRampBox,
    faTruck,
    faPuzzle,
    faConveyorBeltArm,
    faTrophyStar,
    faBagsShopping,
    faBuildingShield,
    faBedBunk
)

function Layout({
    children,
    user,
    metaData,
    terminal
} : any) {

    return (
        <>
            <Background />
            <Header user={user} metaData={metaData} terminal={terminal} />
            <div className="flex h-screen flex-col">
                <div className="w-full pt-16 pb-16">
                    {children}
                </div>
            </div>
        </>
    )
}

export default Layout