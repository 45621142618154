

import { NavLink } from "@remix-run/react"
import { cn } from "@/lib/utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbTack } from '@fortawesome/pro-light-svg-icons'

import { useEffect, useState } from "react"

import { Text } from "@/components/text"

export function NavMenuItem({ item, level, user, setOpen, editPinnedMenu }: any) {
    const [pinned, setPinned] = useState(false)

    useEffect(() => {
        setPinned(window.localStorage.getItem(`pinned-${item.to}`) == 'true')
    }, [])

    return (
        <>
            <div
                className="flex flex-row w-full"
            >
                <NavLink
                    to={item.disabled ? "/" : item.to}
                    onClick={() => {
                        if (setOpen) setOpen(false)
                    }}
                    disabled={item.disabled}
                    className={({ isActive, isPending }) =>
                        cn(
                            "group flex items-center rounded-md px-3 py-2 text-sm font-medium hover:bg-accent hover:text-accent-foreground w-full",
                            isPending ? "bg-accent" : isActive && !item.disabled ? "bg-accent" : "transparent",
                            item.disabled && "cursor-not-allowed opacity-50",
                            'pl-0 pl-8 pl-16 pl-32 pl-64',
                            level > 0 ? `pl-${level * 8}` : 'pl-2'
                        )
                    }
                >
                    <div
                        className="items-center w-6"
                    >
                        <FontAwesomeIcon icon={['fal', item.icon]} className="h-4 w-4" />
                    </div>
                    <div
                        className="grow"
                    >
                        <Text>{item.title}</Text>
                    </div>
                </NavLink>
                {editPinnedMenu && (
                    <div
                        className={cn(
                            "flex justify-center w-8 text-sm font-medium pt-3 cursor-pointer",
                            pinned ? "text-primary" : "text-accent-foreground"
                        )}
                        onClick={() => {
                            window.localStorage.setItem(`pinned-${item.to}`, !pinned ? 'true' : 'false');
                            setPinned(!pinned)
                        }}
                    >
                        <FontAwesomeIcon icon={faThumbTack} className="h-4 w-4" />
                    </div>
                )}
        </div>
        {
            item.navigation && item.navigation.map((subItem: any, subIndex: number) => (
                <NavMenuItem key={subIndex} item={subItem} level={level + 1} user={user} setOpen={setOpen} editPinnedMenu={editPinnedMenu} />
            ))
        }
        </>
    )
}