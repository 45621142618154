import type { LinksFunction, MetaFunction } from "@remix-run/node"
import {
    Link,
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useRouteError,
    useRouteLoaderData
} from "@remix-run/react"
import LogRocket from 'logrocket'
import clsx from "clsx"
import DeviceDetector from "device-detector-js"

import { PreventFlashOnWrongTheme, ThemeProvider, useTheme } from "remix-themes"
import { themeSessionResolver } from "./services/sessions.server"
import { useChangeLanguage } from "remix-i18next/react"
import i18next from "@/services/i18next.server"
import { authenticator } from "@/services/auth.server"
import QRCode from "react-qr-code"

import styles from "../styles/global.css?url"

import Layout from "@/components/layout"
import { useQuery } from "./hooks/erp.server"
import { GlobalLoading } from "./components/globalLoading"
import { useEffect, useState } from "react"
import { pawlTerminal } from "@/services/terminal.server"
import { Toaster } from "./components/ui/toaster"

export const links: LinksFunction = () => [
    { rel: "stylesheet", href: styles },
]

export async function loader({ request } : any) {
    const { query } = useQuery()
    const url          = new URL(request.url)
    const { getTheme } = await themeSessionResolver(request)
    const locale       = await i18next.getLocale(request)
    const isProduction = process.env.NODE_ENV === "production"

    if (url.pathname != "/auth/login") {}

    const user = await authenticator.isAuthenticated(request, {
        ...(url.pathname != "/auth/login" ? { failureRedirect: "/auth/login"} : {})
    })

    const metaData = await query({
        request: request,
        method: "getAll",
        type: "Meta",
        scope: "pawlGlobal",
        data: {}
    })

    const cookieHeader = request.headers.get("Cookie")
    const pawlTerminalSettings = (await pawlTerminal.parse(cookieHeader)) || {}

    return {
        currentTheme : getTheme(),
        locale: locale,
        user: user,
        metaData: metaData,
        pawlTerminalSettings: pawlTerminalSettings,
        isProduction: isProduction
    }
}

export let handle = {
    i18n: "common",
}

export function App() {
    const [theme]          = useTheme()
    const {
        currentTheme,
        locale,
        user, 
        metaData,
        pawlTerminalSettings,
        isProduction
    } = useLoaderData<typeof loader>()
    const deviceDetector = new DeviceDetector()

    LogRocket.init('piuaw7/pawl')

    useEffect(() => {
        if (user && user.guid) {
            LogRocket.identify(user.guid, {
                name: user.firstName + " " + user.lastName,
                email: user.email,
                roles: user.roles,
                isProduction: isProduction
            })
        }
    }, [user])

    useEffect(() => {
        const device = deviceDetector.parse(navigator.userAgent)
        if (device.os && device.os.name == "Android") {
            const honeywellBarcodeReader = document.createElement('script')
            honeywellBarcodeReader.setAttribute('type', 'text/javascript')
            honeywellBarcodeReader.setAttribute('src', '/vendor/honeywell/mobility_sdk/BarcodeReader/BarcodeReader.js')
            document.head.appendChild(honeywellBarcodeReader)
        }
    }, [])

    useChangeLanguage(locale)

    return (
        <html lang={locale} className={clsx(theme)}>
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <Meta />
                <PreventFlashOnWrongTheme ssrTheme={Boolean(currentTheme)} />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <Links />
            </head>
            <body className="h-screen max-h-screen flex flex-col bg-background font-sans antialiased">
                <GlobalLoading />
                <Layout
                    user={user}
                    metaData={metaData}
                    terminal={pawlTerminalSettings}
                >
                    <Outlet />
                    <Toaster />
                </Layout>
                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    )
}


export const meta: MetaFunction = () => {
    return [
        { title: "PAWL" },
        { name: "description", content: "Welcome to CABERO PAWL!" },
    ]
}

export default function AppWithProviders() {
    const data = useLoaderData<typeof loader>()

    return (
        <ThemeProvider specifiedTheme={data.theme} themeAction="/action/set-theme">
            <App />
        </ThemeProvider>
    )
}

export function ErrorBoundary() {
    const [ session, setSession ] = useState<string | null>(null)
    const [ message, setMessage ] = useState<string | null>(null)
    const error                   = useRouteError()

    LogRocket.init('piuaw7/pawl')
    
    useEffect(() => {
        LogRocket.getSessionURL((sessionURL) => {
            setSession(sessionURL)
        })
    }, [])
    
    useEffect(() => {
        if (session) {
            setMessage(btoa(JSON.stringify({
                session: session
            })))
        }
    }, [session])

    console.error(error)

    return (
        <html>
            <head>
                <title>PAWL - Oh no!</title>
                <Meta />
                <Links />
            </head>
            <body
                style={{
                    display        : "flex",
                    justifyContent : "flex-start",
                    alignItems     : "flex-start",
                    flexDirection  : "column",
                    boxSizing      : "border-box",
                    textAlign      : "center",
                    color          : "white",
                    backgroundColor: "#8e4ec6",
                    position       : "absolute",
                    padding        : "1rem",
                    top            : 0,
                    left           : 0,
                    right          : 0,
                    bottom         : 0,
                }}
            >
                <div
                    style={{
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "flex-end",
                        flexDirection : "row",
                        marginBottom  : "0.5rem",
                    }}
                >
                    <h1
                        style={{
                            fontSize: "3rem",
                        }}
                    >
                        :(
                    </h1>
                    <div
                        style={{
                            display       : "flex",
                            justifyContent: "center",
                            alignItems    : "flex-start",
                            flexDirection : "column",
                        }}
                    >
                        <h2
                            style={{
                                marginLeft: "0.8rem",
                                fontSize  : "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Something went wrong
                        </h2>
                        <h2
                            style={{
                                marginLeft: "0.8rem",
                                fontSize  : "2rem",
                            }}
                        >
                            Network error!
                        </h2>
                    </div>
                    
                </div>

                <div
                    style={{
                        fontSize      : "1rem",
                        marginBottom  : "1rem",
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "flex-start",
                        flexDirection : "column",
                        // width         : "100vw",
                    }}
                >
                    <p>
                        If you need help, please contact support.
                    </p>
                    <div
                        style={{
                            fontSize      : "1rem",
                            marginTop     : "1rem",
                            marginBottom  : "1rem",
                            display       : "flex",
                            width         : "100%",
                            justifyContent: "space-between",
                            alignItems    : "flex-start",
                            flexDirection : "row",
                            textDecoration: "underline",
                        }}
                    >
                        <span
                            onClick={() => window.location.reload()}
                        >
                            Retry
                        </span>
                        <Link to="/">Go back to home</Link>
                    </div>
                </div>

                {message && (
                    <QRCode
                        value={message}
                        bgColor="#8e4ec6"
                        fgColor="#ffffff"
                    />
                )}

                <Scripts />
            </body>
        </html>
    )
}