import { cn } from "@/lib/utils"
import { User } from "@/types"
import { MobileSidebar } from "./mobileSidebar"
import { NotificationsNavigation } from "../nav/notificationsNavigation"
import { QuickScanNavigation } from "../nav/quickScanNavigation"
import { CommandNavigation } from "../nav/commandNavigation"
import Logo from "./logo"
import { Link } from "react-router-dom"
import { useEffect } from "react"

export default function Header({ user, metaData, terminal }: { user: User, metaData: any, terminal: any}) {
    if (!user) return null

    return (
        <div className="fixed top-0 left-0 right-0 supports-backdrop-blur:bg-background/60 border-b bg-background/70 backdrop-blur z-20">
            <nav className="h-14 flex justify-center px-2 md:px-4">

                <div className={cn("flex items-center w-12")}>
                    {user && <MobileSidebar navigation={metaData?.navigation} user={user} terminal={terminal} />}
                </div>

                <div className={cn("w-12 lg:w-1")}></div>

                <div className={cn("flex grow lg:hidden")}></div>

                <div className={cn("flex justify-center items-center gap-2 w-[40px]")}>
                    <Link to="/">
                        <Logo
                            size={1}
                        />
                    </Link>
                </div>

                <div className={cn("hidden lg:block w-11")}></div>

                <div className={cn("flex grow ")}></div>

                <div className="flex items-center justify-end w-12 lg:w-auto ">
                    {user && <CommandNavigation user={user} navigation={metaData?.navigation} />}
                </div>

                <div className={cn("flex lg:grow lg!:hidden ")}></div>

                <div className={cn("hidden lg:block w-[40px]")}></div>

                <div className="items-center justify-end w-12 hidden lg:flex">
                    {user && <NotificationsNavigation user={user} />}
                </div>

                <div className="flex items-center justify-end w-12">
                    {user && <QuickScanNavigation user={user} />}
                </div>
            </nav>
        </div>
    )
}