
import { User } from "@/types"
import { Button } from "@/components/ui/button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faGear, faMoonStars, faSunBright } from '@fortawesome/pro-light-svg-icons'
import { Link } from "@remix-run/react"

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import { Text } from "@/components/text"

export function NotificationsNavigation({ user }: { user: User }) {
    const i18nPath = "navigation.notifications"
    return (
        <div className="hidden">
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="outline"
                        size="icon"
                        className="relative"
                    >
                        {/* <span className="absolute top-1 right-1 h-2 w-2 rounded-full bg-primary"></span> */}
                        <FontAwesomeIcon icon={faBell} className="h-[1.2rem] w-[1.2rem] scale-100 transition-all" />
                        <span className="sr-only"><Text path={i18nPath}>title</Text></span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56 mr-4">
                    <DropdownMenuLabel><Text path={i18nPath}>title</Text></DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                        <DropdownMenuItem>
                            <span className="flex h-2 w-2 rounded-full bg-primary mr-3"></span>
                            <Text path={i18nPath}>unread</Text>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                            <span className="flex h-2 w-2 rounded-full border-2 border-primary mr-3"></span>
                            <Text path={i18nPath}>read</Text>
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}