
import { User } from "@/types"
import { Button } from "@/components/ui/button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScannerGun } from '@fortawesome/pro-light-svg-icons'

import { BarcodeScan } from "~/components/barcodeScan"
import { Text } from "@/components/text"
import { useEffect, useState } from "react"
import {
    useNavigation,
    useSubmit
} from "@remix-run/react"
export function QuickScanNavigation({ user }: { user: User }) {
    const i18nPath = "navigation.quickScan"
    const [barcodeOpen, setBarcodeOpen] = useState(false)
    const submitScan = useSubmit()
    const navigation = useNavigation()
    const activ = navigation.state !== 'idle'
    useEffect(() => {
        setBarcodeOpen(false)
    }, [activ])

    return (
        <div className="hidden">
            <Button
                variant="outline"
                disabled
                size="icon"
                onClick={() => {
                    setBarcodeOpen(true)
                }}
            >
                <FontAwesomeIcon icon={faScannerGun} className="h-[1.2rem] w-[1.2rem] scale-100 transition-all" />
                <span className="sr-only"><Text path={i18nPath}>barcode</Text></span>
            </Button>
            <BarcodeScan
                onScan={(scan) => {
                    submitScan({scan}, {method: 'post', action: '/resource/scan'})
                }}
                isOpen={barcodeOpen}
                onOpenChange={setBarcodeOpen}
                barcodeTypeInfo=""
            >
            </BarcodeScan>
        </div>

    )
}