import { Sheet, SheetContent, SheetTrigger, SheetClose } from "@/components/ui/sheet"
import { useSubmit } from "@remix-run/react"
import { Button } from "@/components/ui/button"
import { Text } from "@/components/text"
import { useState } from "react"

import { MainNavigation } from "@/components/nav/mainNavigation"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faPersonToDoor, faXmark } from '@fortawesome/pro-light-svg-icons'
import Logo from "./logo"
import CaberoLogo from "./caberoLogo"
import { ScrollArea } from "@/components/ui/scroll-area"
import { SettingsNavigation } from "../nav/settingsNavigation"

export function MobileSidebar({ navigation, user, terminal }: { navigation : any, user : any, terminal : any }) {
    const i18nPath = "ui.mobileSidebar"
    const [open, setOpen] = useState(false)
    const [editPinnedMenu, setEditPinnedMenu] = useState(false)
    const submit = useSubmit()

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild>
                <Button
                    variant="outline"
                    size="icon"
                >
                    <FontAwesomeIcon icon={faBars} className="h-[1.2rem] w-[1.2rem] scale-100 transition-all" />
                    <span className="sr-only"><Text path={`${i18nPath}.main`}>main</Text></span>
                </Button>
            </SheetTrigger>
            <SheetContent side="left" className="!px-0 w-full p-0">
                <div className="flex flex-col w-full h-full">
                    <div className="h-14 flex justify-center px-4 pt-3 pb-2 w-full bg-secondary/50 border-b">
                        <div className="flex flex-row space-y-1 w-full">
                            <SheetClose asChild>
                                <Button
                                    variant="outline"
                                    size="icon"
                                >
                                    <FontAwesomeIcon icon={faXmark} className="h-[1.2rem] w-[1.2rem] scale-100 transition-all" />
                                    <span className="sr-only"><Text path="global.button">close</Text></span>
                                </Button>
                            </SheetClose>
                            <div
                                className="flex grow justify-center items-center"
                            >
                                <CaberoLogo size={.5} className="mr-4 mt-1" />
                            </div>
                            <SettingsNavigation user={user} terminal={terminal} />
                        </div>
                    </div>
                    <ScrollArea className="flex h-full grow px-3 py-2 pt-4">
                        <MainNavigation items={navigation} setOpen={setOpen} user={user} editPinnedMenu={editPinnedMenu}/>
                    </ScrollArea>
                    <div className="flex justify-center px-4 py-2 w-full bg-secondary/50 border-t">
                        <div className="flex flex-row space-y-1 w-full">
                            <Logo size={1} className="mr-4 mt-1" />
                            <div
                                className="flex flex-col grow"
                            >
                                <div className="text-sm font-bold">{user.firstName} {user.lastName}</div>
                                <div className="text-sm">
                                    {[
                                        user.mandator,
                                        terminal.name
                                    ].filter((item) => item !== null).join(' - ')}
                                </div>
                            </div>
                            <Button
                                variant="outline"
                                size="icon"
                                onClick={() => submit({
                                },
                                    {
                                        method: "GET",
                                        action: '/auth/logout'
                                    })
                                }
                                className="flex text-sm font-bold justify-center items-center p-1"
                            >
                                <FontAwesomeIcon
                                    icon={faPersonToDoor}
                                    className="h-[1.2rem] w-[1.2rem] scale-100 transition-all"
                                />
                                <span className="sr-only"><Text path={i18nPath}>logout</Text></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    )
}